Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.errorTitle = 'Error'
exports.labelTitleText = "Load Board";
exports.newsearch = "New Search";
exports.recent = "Recent";
exports.origin = "Origin";
exports.currentLocation = "Use my current location";
exports.destination = "Destination";
exports.loadWeight = "Load Weight";
exports.startDate = "Start Date";
exports.endDate = "End Date";
exports.truckType = "Truck Type";
exports.searchLoads = "SEARCH LOADS";
exports.searchCityAPIEndPoint = "bx_block_location/city_locations?location=";
exports.searchByFilterAPIEndPoint = "bx_block_load/loads/search_loads_by_city";
exports.totalMiles = "Total Miles";
exports.searchLoadAPIEndpoint = "bx_block_load/loads/search_loads";
exports.filter = "Filter";
exports.sortDistance = "Sort By Distance (mi)";
exports.Textto = "to";
exports.truckType = "Truck Type";
exports.payRange = "Pay Range";
exports.originName = "Origin Name";
exports.destinationName = "Destination Name";
exports.creditScore = "Load Broker Credit Score";
exports.search = "SEARCH";
exports.allLoadsAPIEndPoint = "bx_block_load/loads";
exports.amount = "Amount";
exports.comodity = "Commodity";
exports.originAddress = "Origin Address";
exports.destinationAddress = "Destination Address";
exports.loadDetails = "Load Details";
exports.loarem = "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
exports.trucTypeList = ["Van", "Reefer", "Flatbed", "Stepdeck", "Hotshot", "Box Truck", "Sprinter", "RGN/Lowboy"]
// Customizable Area End
