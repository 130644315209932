import React from "react";

// Customizable Area Start
import {
    Box,
    Input,
    Avatar,
    IconButton,
    Dialog,
    Typography,
    Button
} from "@mui/material";

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import CustomButton from "../../../components/src/CustomButton.web.";
import HeaderNavigationController, {
    Props,
    configJSON
} from "./HeaderNavigationController";
import CloseIcon from '@mui/icons-material/Close';
// Customizable Area End

export default class HeaderNavigation extends HeaderNavigationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderLogoutDialog = () => {
        return (
            <Dialog
                open={this.state.isOpenLogout}
                onClose={this.handleCloseLogoutModal}
                PaperProps={{ style: webStyle.dialogStyle }}
                maxWidth="md"
                data-testId={"openLogoutModal"}
            >
                <Box display={'flex'} justifyContent={'flex-end'}>
                    <CloseIcon onClick={this.handleCloseLogoutModal} />
                </Box>
                <Typography sx={webStyle.fontStyle}>{configJSON.logoutText}</Typography>

                <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} gap={'20px'}
                    sx={{ marginTop: '2rem' }}
                >
                    <Button variant="contained" sx={webStyle.cancelButton} onClick={this.handleCloseLogoutModal}>{configJSON.cancel}</Button>
                    <Button variant="contained" sx={webStyle.LogoutButton} data-testId={"logout_api"} onClick={this.logoutAPI}>{configJSON.logout}</Button>

                </Box>
            </Dialog>
        )
    }

    renderDeleteAccount = () => {
        return (
            <Dialog
                open={this.state.deleteAccount}
                onClose={this.handleDeleteClose}
                PaperProps={{ style: webStyle.dialogStyle }}
                maxWidth="md"
                data-testId={"openDeleteModal"}
            >
                <Box display={'flex'} justifyContent={'flex-end'}>
                    <CloseIcon onClick={this.handleDeleteClose} />
                </Box>
                <Typography sx={webStyle.fontStyle}>{configJSON.logoutText}</Typography>

                <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} gap={'20px'}
                    sx={{ marginTop: '2rem' }}
                >
                    <Button variant="contained" sx={webStyle.cancelButton} data-testId={"delete_api"} onClick={this.handleDeleteAccount}>Delete</Button>
                    <Button variant="contained" sx={webStyle.LogoutButton}  onClick={this.handleDeleteClose}>Keep</Button>

                </Box>
            </Dialog>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const styles = {
            profileIcon: {
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: '#ececec',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
            },
            circleIcon: {
                width: '100%',
                height: '100%',
                backgroundColor: '#fff',
                borderRadius: '50%',
            },
            dropdownMenu: {
              top: '65px',
              right: '-40',
              width: '269px',
              height:'274px',
              backgroundColor: 'white',
              borderRadius: '6px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              overflow: 'hidden',
              zIndex:9
            },
            menuHeader: {

                fontWeight: 400,
                fontFamily: 'Inter',
                width: '211px',
                margin: 'auto',
                height: '31px',
                fontSize: '16px',
                color: '#0F172A',
                marginTop: '20px',
                borderRadius: '8px 12px 8px 12px',
                paddingTop: '10px',
                paddingLeft: '10px',
            },
            selectedSection: {
                backgroundColor: '#FEF5EC',
                borderRadius: '8px 12px 8px 12px',
                // paddingTop:'10px',
                // paddingLeft:'10px',
            },
            menuContent: {
                maxHeight: '200px',
                padding: '10px',
            },
            backgroundLine: {
                left: '33px',
                top: '78',
                width: '2px',
                height: '120px',
                backgroundColor: '#E5E7EB',
            },
            leftSidebar: {
                width: '2px',
                height: '22px',
                backgroundColor: '#C17F33',
                left: '33px',
                top: this.getSidebarPosition().top,
                transition: 'top 0.3s ease-in-out',
            },
            backgroundLine1: {
                left: '33px',
                top: '135px',
                width: '2px',
                height: '22px',
                backgroundColor: '#E5E7EB',
            },
            leftSidebar1: {
                width: '2px',
                height: '22px',
                backgroundColor: '#C17F33',
                left: '33px',
                top: '135px',
                transition: 'top 0.3s ease-in-out',
            },
            backgroundLine2: {
                left: '33px',
                top: '197px',
                width: '2px',
                height: '22px',
                backgroundColor: '#E5E7EB',
            },
            leftSidebar2: {
                width: '2px',
                height: '22px',
                backgroundColor: '#C17F33',
                left: '33px',
                top: '197px',
                transition: 'top 0.3s ease-in-out',
            },
            menuItems: {
                listStyle: 'none',
                padding: '0',
                margin: '0',
                paddingLeft: '50px',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWieght: 400
            },
            menuItem: {
                padding: '8px 0',
                cursor: 'pointer',
                color: '#0F172A',
            },
            menuItemHover: {
                color: 'red',
            },
            selectedItem: {
                fontWeight: 700,
            },
            sectionTitle: {
                color: '#0F172A',
                marginTop: '10px',
                marginBottom: '5px',
                paddingLeft: '27px',
                fontSize: '16px',
                fontWeight: 400,
                fontFamily: 'Inter',
                height: '31px',
                paddingTop: '10px'
            },
            modalButton: {
                padding: '10px 20px',
                marginRight: '10px',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
                fontFamily: 'inter',
                fontSize: '16px',
                fontWeight: 700,
                color: '#B57730',
                width: '117px'
              },
            declineButton: {
                backgroundColor: '#FDF1E2',
              },
              modalBackdrop: {
                position: 'fixed' as 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex:9
              },
              modalContent: {
                backgroundColor: '#fff',
                padding: '20px',
                borderRadius: '8px 8px 32px 8px',
                width: '39%',
                position: 'relative' as 'relative',
                fontFamily:'inter',
                fontSize:'18px',
                fontWeight:400,
                color:'#0F172A',
              },
              modalClose: {
                position: 'absolute' as 'absolute',
                top: '10px',
                right: '10px',
                background: 'none',
                border: 'none',
                fontSize: '24px',
                cursor: 'pointer',
              },
              modalActions: {
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              },


        }
        return (
            <>
            <Box sx={webStyle.root}>
                <Box sx={webStyle.elementBox}>
                    <Box style={webStyle.search_wrapper}>
                        <SearchOutlinedIcon style={webStyle.search_icon} />
                        <Input
                            disableUnderline={true}
                            style={webStyle.input}
                            placeholder={"Search"}
                            />
                    </Box>

                    <Avatar  sx={{...webStyle.avatar}}>
                        {this.props.children}
                    </Avatar>

                
                    <div style={{ position: 'relative', }}>
                    <IconButton>
                        <SettingsOutlinedIcon data-test-id = "avatarBtn" onClick={this.toggleDropdown} sx={webStyle.settingsIcon} />
                    </IconButton>
                        {this.state.isOpen && (
                            <div style={{ position: 'absolute', ...styles.dropdownMenu }}>
                                <div style={{...styles.menuHeader,...(this.state.activeSection === 'account'?styles.selectedSection:{}) }} data-test-id="Accountbtn" onClick={() => this.handleSectionClick('account')}>Account</div>

                                    <div style={{ overflowY: 'auto', ...styles.menuContent }}>

                                    {this.state.activeSection === 'account' && (
                                        <>
                                            <div style={{ position: 'absolute', ...styles.backgroundLine }}></div>
                                            <div style={{ position: 'absolute', ...styles.leftSidebar }}></div>
                                            <ul style={styles.menuItems}>
                                                <li
                                                    style={{
                                                        ...styles.menuItem,
                                                        ...(this.state.selectedItem === 'Personal Details' ? styles.selectedItem : {}),
                                                    }}
                                                    data-test-id = "personalbtn"
                                                    onClick={() => this.handleMenuItemClick('Personal Details')}
                                                    >
                                                    Personal Details
                                                </li>
                                                <li
                                                    style={{
                                                        ...styles.menuItem,
                                                        ...(this.state.selectedItem === 'Change Password' ? styles.selectedItem : {}),
                                                    }}
                                                    data-test-id = "changePassbtn"
                                                    onClick={() => this.handleMenuItemClick('Change Password')}
                                                    >
                                                    Change Password
                                                </li>
                                                <li
                                                    style={{
                                                        ...styles.menuItem,
                                                        ...(this.state.selectedItem === 'Notifications' ? styles.selectedItem : {}),
                                                    }}
                                                    data-test-id = "notificationbtn"
                                                    onClick={() => this.handleMenuItemClick('Notifications')}
                                                    >
                                                    Notifications
                                                </li>
                                                <li
                                                    style={{
                                                        ...styles.menuItem,
                                                        ...(this.state.selectedItem === 'Delete Account' ? styles.selectedItem : {}),
                                                    }}
                                                    data-test-id = "deletebtn"
                                                    onClick={() => this.handleMenuItemClick('Delete Account')}
                                                    >
                                                    Delete Account
                                                </li>
                                            </ul>
                                        </>
                                    )}
                                    
                                        <div data-test-id="generalbtn" onClick={() => this.handleSectionClick('general')} style={{ ...styles.sectionTitle, ...(this.state.activeSection === 'general' ? styles.selectedSection : {}) }}>General</div>

                                        {this.state.activeSection === 'general' && (
                                            <>
                                                <div style={{ position: 'absolute', ...styles.backgroundLine1 }}></div>
                                                <div style={{ position: 'absolute', ...styles.leftSidebar1 }}></div>
                                                <ul style={{ ...styles.menuItems, }}>
                                                    <li
                                                        style={{
                                                            ...styles.menuItem,
                                                            ...(this.state.selectedItem === 'Terms and Conditions' ? styles.selectedItem : {}),
                                                        }}
                                                        onClick={() => this.handleMenuItemClick('Terms and Conditions')}
                                                    >
                                                        Terms and Conditions
                                                    </li>
                                                </ul>
                                            </>
                                        )}

                                        <div onClick={() => this.handleSectionClick('Account Section')} style={{ ...styles.sectionTitle, marginTop: '22px', ...(this.state.activeSection === 'Account Section' ? styles.selectedSection : {}) }}>Account Section</div>

                                        {this.state.activeSection === 'Account Section' && (
                                            <>
                                                <div style={{ position: 'absolute', ...styles.backgroundLine2 }}></div>
                                                <div style={{ position: 'absolute', ...styles.leftSidebar2 }}></div>
                                                <ul style={styles.menuItems}>
                                                    <li
                                                        style={{
                                                            ...styles.menuItem,
                                                            ...(this.state.selectedItem === 'Logout' ? styles.selectedItem : {}),
                                                        }}
                                                        onClick={() => this.handleMenuItemClick('Logout')}
                                                    >
                                                        Logout
                                                    </li>
                                                </ul>
                                            </>
                                        )}


                                    </div>
                                </div>
                            )}
                        </div>

                        <IconButton data-testId={"openModal"} onClick={this.handleOpenLogoutModal}>
                            <ExitToAppOutlinedIcon sx={webStyle.settingsIcon} />
                        </IconButton>
                    </Box>
                </Box>
                {this.renderLogoutDialog()}
                {this.renderDeleteAccount()}
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyle = {
    root: {
        height: '50px',
        backgroundColor: '#215089',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: "16px 40px 16px 40px"
    },
    elementBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '15px'
    },
    search_wrapper: {
        position: "relative" as const,
        backgroundColor: '#FFFFFF',
        borderRadius: "98px",
    },
    search_icon: {
        width: "17.5px",
        color: "#94A3B8",
        height: "17.5px",
        position: "absolute" as const,
        transform: "translateY(-50%)",
        top: "50%",
        left: "10px"
    },
    input: {
        fontFamily: "Inter",
        width: "100%",
        height: "44px",
        padding: "10px 40px 10px 40px",
        fontSize: "16px",
        fontWeight: 400,
        color: '#94A3B8'
    },
    avatar: {
        backgroundColor: 'white',
        width: '48px',
        height: '48px',
    },
    dialogStyle: {
        padding: '20px 40px',
        borderRadius: '8px 8px 32px 8px',
        width: '30%',
        backgroundColor: '#FFFFFF',
        boxShadow: 'box-shadow: 0px 8px 32px 0px #0000000F'
    },
    settingsIcon: {
        height: '24px',
        width: '24px',
        color: 'white',
        cursor: 'pointer'
    },
    fontStyle: {
        fontFamily: 'Inter',
        color: '#0F172A',
        fontSize: '24px',
        fontWeight: 700
    },
    cancelButton: {
        color: '#B57730',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 700,
        backgroundColor: '#FDF1E2',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#FDF1E2',
        }
    },
    LogoutButton: {
        color: '#FFFFFF',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 700,
        cursor: 'pointer',
        background: 'linear-gradient(99.09deg, #915F26 2.64%, #F19F40 100%)',
        '&:hover': {
            background: 'linear-gradient(99.09deg, #915F26 2.64%, #F19F40 100%)',
        }
    },
};

// Customizable Area End
