// Customizable Area Start
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  MenuItem,
  Box
} from "@mui/material";
import Select from '@mui/material/Select';

import TruckerDetailsController, {
  Props,
} from "./TruckerDetailsController.web";

import UnauthLayout from "../../../components/src/UnauthLayout.web";
import {
  PageTitle,
} from "../../../components/src/CustomText.web";
import CustomTextInput from "../../../components/src/CustomTextInput.web";
import CustomButton from "../../../components/src/CustomButton.web.";
import CustomToast from "../../../components/src/CustomToast.web";
import { AuthPageTitle } from "../../../components/src/AuthPageTitle.web";
import CheckIcon from '@mui/icons-material/Check';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const validationSchema = Yup.object({
  truckType: Yup.string()
    .required("Truck Type is required."),
  insurance: Yup.string()
    .trim()
    .required("Insurance name is required.")
    .min(2, "Insurance name must be at least 2 characters long.")
    .max(50, "Insurance name must be between 2 and 50 characters long.")
    .matches(
      /^[a-z ,.'-]+$/i,
      "Insurance name must not contain numbers or special characters."
    ),
  driverlicense: Yup.string()
    .trim()
    .required("Driver license is required.")
    .min(2, "Driver license must be at least 2 characters long.")
    .max(50, " Driver license must be between 2 and 50 characters long.")
    .matches(
      /^[a-zA-Z0-9]+$/,
      "Driver license must not contain special characters."
    ),
  mcnumber: Yup.string()
    .trim()
    .required("MC number is required.")
    .matches(/^\+?\d+$/, "Only numbers are allowed in the MC number")
    .min(2, "MC number is too short. Please enter a valid number.")
    .max(50, "MC number is too long. Please enter a valid number."),
  authority: Yup.string()
    .trim()
    .required("Authority is required.")
    .min(2, "Authority must be at least 2 characters long.")
    .max(50, "Authority must be between 2 and 50 characters long.")
    .matches(
      /^[a-z ,.'-]+$/i,
      "Authority must not contain numbers or special characters."
    ),
});

const newStyles = {
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  
  input: {
    height: '56px',
    radius: '8px',
    padding: '10px 8px 10px 8px',
    width: '100%'
  },
  newBox:{
    textAlign:'center',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-evenly',
    alignItems:'center',
    gap:'30px',
    marginTop:'50%'
    },
    newText:{
    marginTop:'40px',
    fontFamily:"Arial, Helvetica",
    fontSize:'20px',
    fontWeight:400,
    lineHeight:'35px',
    width:'70%'
    } ,
    btn:{
    width:'100%',
    marginTop:'30px'
    },
    successTitile:{
      fontSize:'36px',
      fontWeight:700,
      fontFamily:"Arial, Helvetica",
    },
     
};
// Customizable Area End

export class TruckerDetails extends TruckerDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    return (
      <>
        <UnauthLayout currentWidth={this.state.width}>
        {!this.state.succesPage &&
          <Formik
            initialValues={{
              truckType: "",
              insurance: "",
              driverlicense: "",
              mcnumber: "",
              authority: ""
            }}
            validationSchema={validationSchema}
            onSubmit={
              this.handleSignUp
            }
            data-test-id="trucker-details-formik"
          >
            {(props) => {
              this.updateRegisterFormProps(props);

              return (
                <Form
                  data-test-id="refister-form"
                  style={newStyles.form as React.CSSProperties}
                  onSubmit={props.handleSubmit}
                >

                  {!!this.state.errorMessage && (
                    <CustomToast message={this.state.errorMessage} />
                  )}

                  <PageTitle component="h2" variant="2xl">
                    Sign Up
                  </PageTitle>

                  <Field
                    data-test-id='TruckerSelect'
                    component={() => (
                      <Box style={{ width: '100%' }}>
                        {<AuthPageTitle>Truck Type <span style={{ color: 'red' }}>*</span></AuthPageTitle>}
                        <FormControl sx={{ width: '100%' }}>
                          <Select
                            labelId="demo-multiple-name-label"
                            data-test-id='truckTypeSelectInput'
                            value={props.values.truckType}
                            IconComponent={ArrowDropDownIcon}
                            style={newStyles.input}
                            onChange={this.handleSelectChange("truckType", props)}
                            sx={{
                              height: 50,
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {borderColor: "#CBD5E1" },
                              "&:hover .MuiOutlinedInput-notchedOutline": {borderColor: "#CBD5E1"},
                              "& .MuiSelect-select": {padding: "2px 2px"},
                            }}
                            displayEmpty={true}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  '& .MuiMenuItem-root': {
                                    borderRadius: '0px 0px 8px 8px',
                                    height: '42px',
                                    backgroundColor: '#fff',
                                    padding: '12px 16px',
                                    '&:hover': {
                                      '&.Mui-selected': {
                                        backgroundColor: '#FBE1C4',
                                      },
                                      backgroundColor: '#FBE1C4',
                                    },
                                    '&.Mui-selected': {
                                      backgroundColor: '#FBE1C4',
                                      '&:not(:hover)': {
                                        backgroundColor: '#fff',
                                      },
                                      color: '#000',
                                    },
                                  },
                                },
                              },
                            }}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <span style={{
                                  fontSize: '13px',
                                  color: '#475569',
                                  fontFamily: 'Inter',
                                  opacity: 0.9,
                                }}>{'Select truck type'}</span>;
                              }
                              return selected;
                            }}
                            
                            
                          >
                            {this.truckTypeList.map((name) => (
                                <MenuItem
                                  key={name}
                                  value={name}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                          </Select>
                          {props.touched.truckType && !props.values.truckType && (
                            <div style={{
                              color: 'red',
                              fontSize: 12,
                              fontFamily: 'Inter',
                              marginTop: '5px',
                              fontWeight: '400'
                            }}>
                              {'Truck type is required'}
                            </div>
                          )}
                        </FormControl>
                      </Box>
                    )
                    }
                  />
                  <Field
                    component={CustomTextInput}
                    label="Insurance"
                    data-test-id="txtInputInsurance"
                    placeholder="Insurance name"
                    defaultValue={props.values.insurance}
                    onChange={this.handleChange("insurance", props)}
                    error={this.getFieldError("insurance", props)}
                  />
                  <Field
                    component={CustomTextInput}
                    label="Driver License"
                    data-test-id="txtInputDriverLicense"
                    placeholder="License number"
                    defaultValue={props.values.driverlicense}
                    onChange={this.handleChange("driverlicense", props)}
                    error={this.getFieldError("driverlicense", props)}
                  />

                  <Field
                    component={CustomTextInput}
                    label="MC Number"
                    data-test-id="txtInputMCNumber"
                    placeholder="MC number"
                    defaultValue={props.values.mcnumber}
                    onChange={this.handleChange("mcnumber", props)}
                    error={this.getFieldError("mcnumber", props)}
                  />
                  <Field
                    component={CustomTextInput}
                    label="Authority"
                    data-test-id="txtInputAuthority"
                    placeholder="authority"
                    defaultValue={props.values.authority}
                    onChange={this.handleChange("authority", props)}
                    error={this.getFieldError("authority", props)}
                  />
                  <Box sx={{marginTop:'20px'}}/>
                  <CustomButton
                    text="Done"
                    testId="btnEmailRegister"
                    onClick={props.handleSubmit}
                  />
                </Form>
              );
            }}
          </Formik>
        }
          {
            this.state.succesPage &&
              (
                <>
                <Box sx={newStyles.newBox}>
                <Box component="h2" sx={newStyles.successTitile}>
                  {'Congratulations !'}
                </Box>
                <Box>
                  <CheckIcon style={{color:'white',width:'50.23px',height:'50.07',border:'2px solid #34D399',borderRadius:'40px',background:'#34D399'}} />
                </Box>
                <Box sx={newStyles.newText}>
                {'Your account has been  successfully created.'}
                </Box>

                <Box sx={newStyles.btn}>
                    <CustomButton
                    testId="btnContinue"
                    onClick={this.sendTodashboard}
                    text="CONTINUE"
                  />
                    </Box>
                </Box>
                </>
              )
          }
        </UnauthLayout>

      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default TruckerDetails;
// Customizable Area End
