import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData} from "../../../framework/src/Utilities";

  interface Address {
    city: string;
    state: string;
    address: string;
    country: string;
    zip_code: string;
  };
  interface DriverDetails {
    id: string;
    type: string,
    attributes:{
        load_weight:string,
        total_miles:string,
        applications_count:0,
        origin_address: Address;
        destination_address: Address;
        price: string;
        status: string;
        start_date: string;
        end_date: string;
        commodity: string;
        load_details: string;
        avatarSrc: string;
    },
  }
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    tabValue: number;
    loadsDataList: DriverDetails[];
    selectedCardId: string;
    status: string;
    applicantsList: string[];
    expandedApplicantId: string;
    selectedStatuses: string[];
    // Customizable Area End
}
interface SS { }

export default class MyLoadsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    showAllMyLoadsAPICallId:string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            tabValue: 0,
            selectedCardId: '0',
            status: '',
            loadsDataList: [],
            applicantsList: ['1','2','3'],
            expandedApplicantId: '0',
            selectedStatuses: [],
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        this.showAllMyLoads()
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start  
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && !responseJson.errors) {
                if (apiRequestCallId === this.showAllMyLoadsAPICallId) {
                    this.handleAllMyLoadData(responseJson);
                }
            }
        }     
        // Customizable Area End
    }
    // Customizable Area Start
    handleAllMyLoadData=(responseJson:{ data: DriverDetails[] })=>{     
        this.setState({loadsDataList:responseJson.data},()=>{
            this.setState({selectedCardId:this.state?.loadsDataList[0].id})
        })
    }
    showAllMyLoads = async () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: await getStorageData("LoginToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.showAllMyLoadsAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.myLoadsPath);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    handleCardClick = (cardId: string) => {
        this.setState({ selectedCardId: cardId });
    };
    isCardSelected = (cardId: string): boolean => {
        return cardId === this.state.selectedCardId;
    };
    handleApplicant = (applicantId: string) => {
        this.setState({
          expandedApplicantId: applicantId,
        });
    };
    handleApplicantDetail = () => {
        this.setState({
          expandedApplicantId: '0',
        });
    };
    handleChange = (event:any) => {
        const value = event.target.value;
          this.setState({ selectedStatuses: value });
      };
    // Customizable Area End
}