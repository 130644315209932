import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { SelectChangeEvent } from '@mui/material/Select'
import { City } from "./LoadBrokerSearchController.web";
import moment from "moment";

export interface AllLoads {
    id: number;
    load_weight: string;
    total_miles: string;
    origin_address: {
        city: string;
        state: string;
        street: string;
        country: string;
        zip_code: string;
        door_number: string
    },
    destination_address: {
        city: string;
        state: string;
        street: string;
        country: string;
        zip_code: string;
        door_number: string;
    },
    start_date: string;
    end_date: string;
    price: string;
    load_broker_credit_score: string;
    load_broker_phone_number: string;
    created_at: string;
    updated_at: string;
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    selectedValue: string;
    selectedOrigin: City | null;
    selectedDestination: string;
    startDate: Date | null;
    endDate: Date | null;
    allLoadsdata: AllLoads[];
    currentPage: number;
    itemsPerPage: number;
    loadDetails: AllLoads | null;
    selectedLoadId: number | null;
    distanceFrom: string;
    distanceTo: string;
    loadWeightFrom: string;
    loadWeightTo: string;
    payRangeFrom: string;
    payRangeTo: string;
    creditScoreFrom: string;
    creditScoreTo: string;
    cityOptions:City[],
    originInputValue:string,
    destinationInputValue:string,

    // Customizable Area End
}
interface SS { }

export default class LoadBoardListViewController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    showAllLoadsAPICallId: string = "";
    showLoadDetailsAPICallId: string = "";
    getSearchedCitiesAPICallId: string = "";
    getSearchedByFilterAPICallId: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            selectedValue: 'Van',
            selectedOrigin: null,
            selectedDestination: "Denver",
            startDate: null,
            endDate: null,
            allLoadsdata: [],
            currentPage: 1,
            itemsPerPage: 4,
            loadDetails: null,
            selectedLoadId: null,
            distanceFrom: "0",
            distanceTo: "500",
            loadWeightFrom: "0",
            loadWeightTo: "150",
            payRangeFrom: "0",
            payRangeTo: "0",
            creditScoreFrom: "350",
            creditScoreTo: "800",
            cityOptions: [],
            originInputValue:"San Diego",
            destinationInputValue:"Denver"
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        this.showAllLoads()
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let webResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            switch (webApiRequestCallId) {
                case this.showAllLoadsAPICallId:
                    this.handleAllLoadsAPIResponse(webResponseJson);
                    break;

                case this.showLoadDetailsAPICallId:
                    this.handleLoadDetailsAPIResponse(webResponseJson);
                    break;

                case this.getSearchedCitiesAPICallId: 
                   this.setState({ cityOptions: webResponseJson?.data });
                   break;

                case this.getSearchedByFilterAPICallId:
                    break;

                default:
                    break;
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start

    dateFormate = (date: string, format:string) => {
       return moment(date).format(format);
    }
    // Events
    handleCardClick = (loadId: number) => {
        this.setState({ selectedLoadId: loadId });
        this.showLoadDetails(loadId);
    };

    handleChange = (event:SelectChangeEvent) => {
        this.setState({ selectedValue: event.target.value });
    };

    handleStartDateChange = (date: Date | null) => {
        this.setState({ startDate: date });
    }

    handleEndDateChange = (date: Date | null) => {
        this.setState({ endDate: date });
    };

    handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        this.setState({ currentPage: page });
    }

    getPaginatedData = () => {
        const { currentPage, itemsPerPage, allLoadsdata } = this.state;
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return allLoadsdata.slice(startIndex, endIndex);
    }

    // API

    showAllLoads = async () => {
        const header = {
            "Content-Type": configJSON.advancedsearchApiContentType,
            token: await getStorageData("LoginToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.showAllLoadsAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.allLoadsAPIEndPoint);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    showLoadDetails = async (index: number) => {
        const header = {
            "Content-Type": configJSON.advancedsearchApiContentType,
            token: await getStorageData("LoginToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.showLoadDetailsAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.allLoadsAPIEndPoint}/${index}`);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleAllLoadsAPIResponse = (webResponseJson: { data: AllLoads[] }) => {
        if (webResponseJson) {
            const loadsData = webResponseJson?.data;
            const firstLoadId = loadsData?.length > 0 ? loadsData[0].id : null;
            this.setState({
                allLoadsdata: loadsData,
                selectedLoadId: firstLoadId
            }, () => {
                if (firstLoadId) {
                    this.showLoadDetails(firstLoadId);
                }
            });
        }

    }

    handleLoadDetailsAPIResponse = (webResponseJson: { data: AllLoads }) => {
        if (webResponseJson) {
            this.setState({ loadDetails: webResponseJson?.data });
        }
    }

    isValidInput = (value: string): boolean => {
        return /^\d*$/.test(value);
    };

    handleChangeDistanceFrom = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidInput(event.target.value) && this.setState({distanceFrom:event.target.value});
    }

    handleChangeDistanceTo = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidInput(event.target.value) && this.setState({distanceTo:event.target.value});
    }

    handleChangeLoadWeightFrom = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidInput(event.target.value) && this.setState({loadWeightFrom:event.target.value});
    }

    handleChangeLoadWeightTo = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidInput(event.target.value) && this.setState({loadWeightTo:event.target.value});
    }

    handleChangePayRangeFrom = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidInput(event.target.value) && this.setState({payRangeFrom:event.target.value});
    }

    handleChangePayRangeTo = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidInput(event.target.value) && this.setState({payRangeTo:event.target.value});
    }
    
    handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
       this.setState({ originInputValue: value },
            () => this.getAllSearchedCities(true)
        );
    };

    handleDestinationInputChange = (event: React.ChangeEvent<{}>, value: string) => {
        this.setState({ destinationInputValue: value },
            () => this.getAllSearchedCities(false)
        );
    };

    handleCityChange = (event: React.ChangeEvent<{}>, newValue: City | null) => {
        newValue && this.setState({ selectedOrigin: newValue });
        
    };

    getAllSearchedCities = async(origin:boolean) =>{
        const header = {
            "Content-Type": configJSON.advancedsearchApiContentType,
            token: await getStorageData("LoginToken")
        };

        const endpoint  = origin 
            ? `${configJSON.searchCityAPIEndPoint}${this.state.originInputValue}`
            : `${configJSON.searchCityAPIEndPoint}${this.state.destinationInputValue}`

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSearchedCitiesAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage); 
    }

    handleChangeCreaditScoreFrom = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidInput(event.target.value) && this.setState({creditScoreFrom:event.target.value});
    }

    handleChangeCreaditScoreTo = (event: React.ChangeEvent<HTMLInputElement>)=> {
        this.isValidInput(event.target.value) && this.setState({creditScoreTo:event.target.value});
    }

    searchByFilter = async() => {
        const header = {
            "Content-Type": configJSON.advancedsearchApiContentType,
            token: await getStorageData("LoginToken")
        };

        const endpoint = `${configJSON.searchByFilterAPIEndPoint}`


        
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSearchedByFilterAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage); 
    }
    // Customizable Area End
}