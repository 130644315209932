import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { FormikProps } from "formik";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { SelectChangeEvent } from '@mui/material/Select';

interface ApiResponse {
  errors?: {
    truck_type: string;
    insurance: string;
    driving_license_number: string;
    mc_number: string;
    authority: string;
  }[];
  data?: {
    id: string;
    type: string;
    attributes: {
      truck_type: string;
      insurance: string;
      driving_license_number: string;
      mc_number: string;
      authority: string;
    };
  };
  meta?: {
    token: string;
  };
  message?: string;
  token?: string;
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}
interface FormValues {
  truckType: string;
  insurance: string;
  driverlicense: string;
  mcnumber: string;
  authority: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  width: number;
  enablePasswordField: boolean;
  enableConfirmPasswordField: boolean;
  errorMessage: string;
  email: string;
  showModal: boolean;
  isDisable: boolean;
  userType: string;
  succesPage: boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class TruckerDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountLoginId: string = "";
  registerFormProps?: FormikProps<{
    truckType: string;
    driverlicense: string;
    insurance: string;
    mcnumber: string;
    authority: string;
  }> = undefined;
  truckTypeList = [
    'VAN',
    'REEFER',
    'FLATBED',
    'STEPDECK',
    'HOTSHOT',
    'BOX TRUCK',
    'SPRINTER',
    'RGN/LOWBOY'
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.state = {
      width: window.innerWidth,
      enablePasswordField: true,
      enableConfirmPasswordField: true,
      errorMessage: "",
      email: '',
      showModal: false,
      isDisable: true,
      userType: '',
      succesPage: false,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    window.addEventListener("resize", () => this.handleWidthChange());
    // Customizable Area End
  }

  // Customizable Area Start
  handleWidthChange = () => {
    this.setState({ width: window.innerWidth });
  };

  updateRegisterFormProps = (
    props: FormikProps<{
      truckType: string;
      insurance: string;
      driverlicense: string;
      mcnumber: string;
      authority: string;
    }>
  ) => {
    this.registerFormProps = props;
  };

  handleSuccess = (response: any) => {
    if (response) {
      this.handleSignUp(response)

    }
  };

  handleError = () => { };
  handleTogglePassword = () =>
    this.setState({ enablePasswordField: !this.state.enablePasswordField });

  handleToggleConfirmPassword = () =>
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });

  sendTodashboard = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }


  handleSignUp = (values: any) => {
    const header = {
      "token": localStorage.getItem('LoginToken')
    };
    const formData = new FormData();
    formData.append('truck_type[]', values.truckType);
    formData.append('insurance', values.insurance);
    formData.append('driving_license_number', values.driverlicense);
    formData.append('mc_number', values.mcnumber);
    console.log({formData})
    formData.append('authority', values.authority);
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.createAccountLoginId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/profiles/update_user_details'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePUT);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleErrorResponse(response: ApiResponse) {
    response.errors?.forEach((error) => {
      if (error.truck_type) {
        this.setState({ errorMessage: error.truck_type });
      }
      if (error.insurance) {
        this.setState({ errorMessage: error.insurance });
      }
      if (error.driving_license_number) {
        this.setState({ errorMessage: error.driving_license_number });
      }
      if (error.mc_number) {
        this.setState({ errorMessage: error.mc_number });
      }
      if (error.authority) {
        this.setState({ errorMessage: error.authority });
      }
    });
  }

  handleCreateAccount(response: ApiResponse) {
    if (response.errors) {
      this.handleErrorResponse(response)
    }
    else if (response.meta && response.data) {
      this.setState({ succesPage: true })
    }
  }

  handleChange = (
      field: string, 
      props: FormikProps<FormValues>
    ) => (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setFieldValue(field, e.target.value);
    this.setState({ errorMessage: '' });
  };

 

  getFieldError = (field:keyof FormValues, props:FormikProps<FormValues>) =>
    props.touched[field] && !!props.errors[field]
      ? props.errors[field] : "" ;

  handleSelectChange = (
      field:string, 
      props:FormikProps<FormValues>
    ) => (e: SelectChangeEvent<string>) => {
    props.setFieldValue(field, e.target.value);
    this.setState({ errorMessage: '' });
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage) );
    if (apiRequestCallId === this.createAccountLoginId) { this.handleCreateAccount(responseJson) }
    // Customizable Area End
  }
}
