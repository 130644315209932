import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    Stepper,
    Step,
    StepLabel,
    StepConnector,
    Button,
    Avatar,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    MenuItem,
    Select,
    Divider,
} from "@mui/material";
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import {
  styled, createTheme, ThemeProvider
} from "@mui/material/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import HeaderNavigation from "../../navigationmenu/src/HeaderNavigation.web";
import MyLoadsController, {
    Props,
    configJSON
} from "./MyLoadsController.web";
import { trcukdetailsIcon, truckIcon } from "./assets";
import {MoreVert, Circle, ExpandMoreRounded, KeyboardArrowDown, ExpandLessRounded, Phone, ChatBubbleOutlineOutlined, LocationOnOutlined} from '@mui/icons-material';

const BpIcon1 = styled('span')(({ theme }) => ({
  height: 20,
  width: 20,
  borderRadius: "6px",
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  marginLeft:"-9px",
  'input:hover ~ &': {
      backgroundColor: '#EBF1F5',
  },
  'input:disabled ~ &': {
      boxShadow: 'none',
  },
}));
const BpCheckedIcon1 = styled(BpIcon1)({
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  backgroundColor: '#B57730',
  '&::before': {
    height: 20,
    width: 20,
    display: 'block',
      backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
  },
  'input:hover ~ &': {
      backgroundColor: '#B57730',
  },
});
const BpCheckbox1 = (props: CheckboxProps) => {
  return (
      <Checkbox
      checkedIcon={<BpCheckedIcon1 />}
      color="default"
      icon={<BpIcon1 />}
      disableRipple
          inputProps={{ 'aria-label': 'list-data' }}
          {...props}
      />
  );
}
const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: '20px',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '20px',
          backgroundColor:'#f1f5f9',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #CBD5E1',               
        },}
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'white',
          },
          '&:focus-visible': {
            outline: 'none', 
          },
          '&.Mui-selected':{
            backgroundColor: 'white',
            '&:hover': {
              backgroundColor: 'white',
            },
          },
        },
      },
    },
    MuiAccordionDetails:{
      styleOverrides: {
      root:{
        backgroundColor: 'white',
        padding: '0px',
        
      }
      }
    },
    MuiButton:{
      styleOverrides: {
      root:{ 
        '&:focus-visible': { outline: 'none', },       
        '&:hover': {
          backgroundColor: '#FBE1C4',
        }        
      }
      }
    },
    MuiSvgIcon:{
      styleOverrides: {
      root:{        
        fontSize: '30px',      
      }
      }
    },
  },
});
// Customizable Area End

export default class MyLoads extends MyLoadsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const selectedCard = this.state.loadsDataList.find(card => card.id === this.state.selectedCardId);
        return (
            //Merge Engine DefaultContainer
            <Box sx={webStyles.container}>
                <HeaderNavigation navigation={this.props.navigation} id={""} />
                <Box sx={webStyles.content}>
                    <NavigationMenu navigation={this.props.navigation} id={""} />
                    <ThemeProvider theme={theme}>
                    <Box sx={webStyles.mainContent}>
                        <Box sx={webStyles.tabContainer}>
                            <Typography style={webStyles.headerFont}>{configJSON.myLoadsText}</Typography>
                            <Box sx={{
                              width: '327px'
                            }}>
                              <FormControl sx={{ m: 1, width: 300 }}>

                                <Select
                                  labelId="demo-multiple-name-label"
                                  id="demo-multiple-name"
                                  value={this.state.selectedStatuses}
                                  onChange={this.handleChange}
                                  multiple
                                  data-testId = 'selectFilter'
                                  style={webStyles.input}
                                  sx={{
                                    height: 50
                                  }}

                                  displayEmpty={true}
                                  renderValue={(selected) => {
                                    if (selected.length === 0) {
                                      return <span style={webStyles.selectStyle}>Filter Status</span>;
                                    }
                                    return selected.join(', ');
                                  }}
                                  IconComponent={KeyboardArrowDown}
                                >
                                  {['All','In Transit', 'Road Closure', 'Break Down', 'Weather Delay',].map((name) => (

                                      <MenuItem
                                        key={name}
                                        value={name}
                                      >
                                        <BpCheckbox1 checked={this.state.selectedStatuses.includes(name)}/>
                                        {name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Box>
                            <Grid container spacing={3}>
                              <Grid item xs={7} sx={webStyles.styledCardContainer}>
                                {this.state.loadsDataList.map((cardItem) => (
                                  <Box
                                    sx={{
                                      ...webStyles.cardStyle,
                                      border: this.isCardSelected(cardItem.id) ? '2px solid #7b6344' : '',
                                      boxShadow: this.isCardSelected(cardItem.id) ? '0px 4px 4px 0px #a87f51' : '0px 4px 4px 0px #00000040',
                                    }}
                                    key={cardItem.id}
                                    data-testId='load-cards'
                                    onClick={() => this.handleCardClick(cardItem.id)}
                                  >
                                    <Box sx={webStyles.cardHeader}>
                                      <Box sx={webStyles.avatarContainer}>
                                      <Typography sx={{...webStyles.fontStyle_6, fontSize: '24px'}}>#ID {cardItem.id}</Typography>
                                        <MoreVert />
                                      </Box>
                                    </Box>

                                    <Box display={'flex'} flexDirection={'column'} sx={webStyles.boxPadding}>
                                    <Box style={webStyles.justifySpace} alignItems='center' >
                                      <Box >
                                        <Typography sx={webStyles.fontSyle_4}
                                        >{cardItem.attributes.load_weight} - {cardItem.attributes.total_miles}</Typography>
                                      </Box>
                                      <Box >
                                        <Typography sx={{
                                          ...webStyles.fontSyle_3,
                                        }}
                                        >
                                          No of Applicants : {cardItem.attributes.applications_count}
                                        </Typography>
                                      </Box>                                      
                                      </Box>  
                                      <Box sx={{ ...webStyles.justifySpace,alignItems:'center', }}>
                                        <Box sx={webStyles.statusBox} >
                                          <Stepper
                                            orientation="vertical"
                                            connector={<CustomConnector />}
                                          >
                                              <Step>
                                                <StepLabel
                                                  style={{padding:0}}
                                                  icon={<div style={{borderRadius:'50%', backgroundColor:'#b4c8d9', height:'10px', width:'10px'}}></div>}
                                                >
                                                  <Typography sx={webStyles.dateFontSyle_1}>{cardItem.attributes.origin_address?.city}</Typography>
                                                </StepLabel>
                                              </Step>
                                              <Step >
                                              <StepLabel style={{padding:0}}
                                                icon={<Circle sx={{fontSize:"11px"}} /> }
                                              >
                                                <Typography sx={webStyles.dateFontSyle_1}>{cardItem.attributes.destination_address?.city}</Typography>
                                              </StepLabel>
                                            </Step>
                                          </Stepper>
                                        </Box>
                                        <Typography style={webStyles.priceText}>$ {cardItem.attributes.price}</Typography>
                                      </Box>
                                    </Box>

                                    <Box sx={webStyles.viewBox}>
                                      <Typography sx={webStyles.fontSyle_5}>{cardItem.attributes.status}</Typography>
                                      <Button sx={{...webStyles.buttonStyle, textTransform:'none'}}>View Details</Button>
                                    </Box>

                                  </Box>
                                ))}
                              </Grid>
                              <Grid item xs={5}>
                              {selectedCard && (
                                <Box sx={webStyles.driverInfoCard}>
                                  <Box sx={webStyles.infoBoxPadding}>
                                  <Box sx={webStyles.mainTruckBox}>
                                    <Box sx={webStyles.trackingBoxStyle}>
                                      <Box sx={webStyles.boxColumn}>
                                        <Box sx={webStyles.trackingDetails}>
                                          <Typography sx={{...webStyles.fontStyle_5, fontSize: '12px'}}>{configJSON.idNo}</Typography>
                                          <Typography sx={webStyles.fontStyle_6}>{selectedCard.id}</Typography>
                                        </Box>

                                        <Box sx={webStyles.trackingDetails}>
                                          <Typography sx={{...webStyles.fontStyle_5, fontSize: '12px'}}>{configJSON.from}</Typography>
                                          <Typography sx={webStyles.fontStyle_6}>{selectedCard.attributes.origin_address?.city}</Typography>
                                        </Box>

                                        <Box sx={webStyles.trackingDetails}>
                                          <Typography sx={{...webStyles.fontStyle_5, fontSize: '12px'}}>{configJSON.weight}</Typography>
                                          <Typography sx={webStyles.fontStyle_6}>{selectedCard.attributes.load_weight}</Typography>
                                        </Box>
                                      </Box>

                                      <Box sx={webStyles.boxColumn}>
                                        <Box sx={webStyles.trackingDetails}>
                                          <Typography sx={{...webStyles.fontStyle_5, fontSize: '12px'}}>{configJSON.trukerApplied}</Typography>
                                          <Typography sx={webStyles.fontStyle_6}>{selectedCard.attributes.applications_count}</Typography>
                                        </Box>

                                        <Box sx={webStyles.trackingDetails}>
                                          <Typography sx={{...webStyles.fontStyle_5, fontSize: '12px'}}>{configJSON.to}</Typography>
                                          <Typography sx={webStyles.fontStyle_6}>{selectedCard.attributes.destination_address?.city}</Typography>
                                        </Box>

                                        <Box sx={webStyles.trackingDetails}>
                                          <Typography sx={{...webStyles.fontStyle_5, fontSize: '12px'}}>{configJSON.amount}</Typography>
                                          <Typography sx={webStyles.fontStyle_6}>${selectedCard.attributes.price}</Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box display={'flex'} justifyContent={'flex-end'}>
                                          <img src={trcukdetailsIcon} width='315px' height='165px'/>
                                      </Box>
                                      </Box>
                                    <Box style={{ overflow: "hidden", backgroundColor: '#e9eef2', marginBottom:'40px' }}>
                                        <Accordion style={webStyles.allSelectColor}>
                                            <Box style={{ padding: '20px' }}>
                                                <AccordionSummary expandIcon={<ExpandMoreRounded />}>
                                                    <Typography style={webStyles.allFilterFont}>Applicants</Typography>
                                                </AccordionSummary>
                                            </Box>
                                            <AccordionDetails>
                                              <Box style={{ ...webStyles.coverBox, flexDirection: 'column' }}>
                                                {this.state.expandedApplicantId === '0' &&
                                                  <Typography style={{ ...webStyles.fontStyle_5, color: '#465567' }}>Here you can see the details of the load applicants you want to approve or reject.</Typography>
                                                }
                                                {this.state.applicantsList.map((applicant) => (
                                                  <>
                                                    {this.state.expandedApplicantId === '0' &&
                                                      <Box key={applicant} data-testId='applicant-list' onClick={() => this.handleApplicant(applicant)} sx={webStyles.driverDetails}>
                                                        <div style={{ display: 'flex', gap: '1.5rem' }}>
                                                          <Avatar sx={webStyles.driverPick} alt="#avataricon" src={selectedCard.attributes.avatarSrc} />
                                                          <Box sx={webStyles.nameContainer}>
                                                            <Typography sx={webStyles.fontStyle_7}>Chaster Hele</Typography>
                                                            <Typography sx={{ ...webStyles.fontStyle_5, color: '#334155' }}>Insurance</Typography>
                                                            <Typography sx={{ ...webStyles.fontStyle_5, color: '#334155' }}>Authority</Typography>
                                                          </Box>
                                                        </div>
                                                        <Box><ExpandMoreRounded /></Box>
                                                      </Box>}
                                                    {this.state.expandedApplicantId === applicant && (
                                                      <Box style={webStyles.detailBox1}>
                                                        <div style={{ backgroundColor: '#E2E8F0', borderRadius: '8px', paddingBottom: '35px' }}>
                                                          <Box display={'flex'} justifyContent={'flex-end'} padding={'15px 15px 0 15px'} data-testId='applicant-Detail' onClick={() => this.handleApplicantDetail()} ><ExpandLessRounded /></Box>
                                                          <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Avatar style={{ width: "132px", height: '132px' }} alt="#avataricon" src={selectedCard.attributes.avatarSrc} />
                                                          </Box>

                                                          <Typography style={webStyles.applicantName}>Chaster Hele</Typography>
                                                          <Box style={{ ...webStyles.chatBox, textAlign: 'center', margin: '18px 0' }}>
                                                            <Phone style={webStyles.phone1} />
                                                            <Box style={{ ...webStyles.fontStyle_5, color: '#334155' }}>+1 3783912983</Box>
                                                          </Box>
                                                          <Box style={{ ...webStyles.chatBox, textAlign: 'center', }}>
                                                            <Box sx={{ ...webStyles.chatBoxStyle1, boxShadow: '0px 4px 4px 0px #A77F5161' }} >
                                                              <ChatBubbleOutlineOutlined />
                                                              <Typography>Chat Now</Typography>
                                                            </Box>
                                                          </Box>
                                                        </div>
                                                        <div>
                                                          <Box style={{ margin: '66px 66px 40px 66px' }}>
                                                            <Box style={webStyles.justifySpace}>
                                                              <Box style={{ ...webStyles.flexBox, boxSizing: 'border-box' }}>
                                                                <Typography style={webStyles.flexText}>Insurance</Typography>
                                                              </Box>
                                                              <Box style={{ ...webStyles.flexBox, boxSizing: 'border-box' }}>
                                                                <Typography style={webStyles.flexText}>Authority</Typography>
                                                              </Box>
                                                            </Box>
                                                          </Box>
                                                          <Box style={{ margin: '0px 66px 84px 66px' }}>
                                                            <Box style={webStyles.justifySpace}>
                                                              <Box style={{ ...webStyles.flexBox, boxSizing: 'border-box' }}>
                                                                <Typography style={webStyles.flexText}>Driver License</Typography>
                                                              </Box>
                                                              <Box style={{ ...webStyles.flexBox, boxSizing: 'border-box' }}>
                                                                <Typography style={webStyles.flexText}>MC Number</Typography>
                                                                <Typography style={{ ...webStyles.flexText, fontWeight: 400, marginTop: "10px" }}>78H678H8HJ</Typography>
                                                              </Box>
                                                            </Box>
                                                          </Box>
                                                          <Box style={{ ...webStyles.justifySpace, margin: '0px 66px 90px 66px' }}>
                                                            <Button variant="contained" sx={webStyles.cancelButton} >REJECT</Button>
                                                            <Button variant="contained" sx={webStyles.LogoutButton} >APPROVE</Button>
                                                          </Box>
                                                        </div>
                                                      </Box>
                                                    )}
                                                  </>
                                                ))}

                                              </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                     
                                    <Box style={{ overflow: "hidden", backgroundColor: '#e9eef2', marginBottom:'40px' }}>
                                        <Accordion style={webStyles.allSelectColor}>
                                            <Box style={{ padding: '20px' }}>
                                                <AccordionSummary expandIcon={<ExpandMoreRounded />}>
                                                    <Typography style={webStyles.allFilterFont}>Route Details</Typography>
                                                </AccordionSummary>
                                            </Box>
                                            <AccordionDetails>
                                              <Box style={{ ...webStyles.coverBox1 }}>
                                                <Box style={{ ...webStyles.routeBox, display:'flex',flexDirection: 'row',boxSizing: 'border-box',marginBottom:'22px' }}>
                                                  <img width={'101px'} height={'62px'} src={truckIcon} />
                                                  <Box style={{display:'flex',flexDirection: 'column' }}>
                                                    <Typography style={webStyles.fontStyle_9}>Status</Typography>
                                                    <Typography style={{...webStyles.fontStyle_6, fontSize:'18px', textTransform:'capitalize'}}>{selectedCard.attributes.status}</Typography>
                                                  </Box>
                                                </Box>
                                                <Box style={{ ...webStyles.routeBox, display:'flex',justifyContent:'space-between',boxSizing: 'border-box' }}>
                                                  
                                                  <Box style={{display:'flex',flexDirection: 'column' }}>
                                                    <Typography style={{...webStyles.fontStyle_9, marginBottom:'20px'}}>Live Location</Typography>
                                                    <Typography style={{...webStyles.fontStyle_6, fontSize:'18px', textTransform:'capitalize'}}>822 E. 20th Street,</Typography>
                                                    <Typography style={{...webStyles.fontStyle_6, fontSize:'18px', textTransform:'capitalize'}}>Los Angeles,</Typography>
                                                    <Typography style={{...webStyles.fontStyle_6, fontSize:'18px', textTransform:'capitalize'}}>California</Typography>
                                                    <Typography style={{...webStyles.fontStyle_6, fontSize:'18px', textTransform:'capitalize'}}>90011</Typography>
                                                  </Box>
                                                  <Box style={webStyles.locationBox}><LocationOnOutlined sx={{color:'#fff', margin: '18px'}}/></Box>
                                                </Box>
                                              </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                        
                                    </Box>

                                    <Box style={{ overflow: "hidden", backgroundColor: '#e9eef2', marginBottom:'40px' }}>
                                        <Accordion style={webStyles.allSelectColor}>
                                            <Box style={{ padding: '20px' }}>
                                                <AccordionSummary expandIcon={<ExpandMoreRounded />}>
                                                    <Typography style={webStyles.allFilterFont}>Shipment Details</Typography>
                                                </AccordionSummary>
                                            </Box>
                                            <AccordionDetails>
                                              <Box style={{ ...webStyles.coverBox, flexDirection: 'column' }}>
                                                <Box display={'flex'} flexDirection={'column'} gap={'1rem'} p={'30px'}>
                                                  <Box display={'flex'} gap={'7.5rem'}>
                                                    <Typography sx={webStyles.fontStyle_9}>{configJSON.startDate}</Typography>
                                                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{selectedCard?.attributes.start_date}</Typography>
                                                  </Box>
                                                  <Divider sx={webStyles.dividerStyles} />

                                                  <Box style={webStyles.displayFlexBox}>
                                                    <Typography sx={webStyles.fontStyle_9}>{configJSON.endDate}</Typography>
                                                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{selectedCard?.attributes.end_date}</Typography>
                                                  </Box>
                                                  <Divider sx={webStyles.dividerStyles} />

                                                  <Box style={webStyles.displayFlexBox}>
                                                    <Typography sx={webStyles.fontStyle_9}>{configJSON.comodity}</Typography>
                                                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{'Goods'}</Typography>
                                                  </Box>
                                                  <Divider sx={webStyles.dividerStyles} />

                                                  <Box style={webStyles.displayFlexBox}>
                                                    <Typography sx={webStyles.fontStyle_9}>{configJSON.totalMiles}</Typography>
                                                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{selectedCard?.attributes.total_miles}</Typography>
                                                  </Box>
                                                  <Divider sx={webStyles.dividerStyles} />

                                                  <Box style={webStyles.displayFlexBox}>
                                                    <Typography sx={{ ...webStyles.fontStyle_9 }}>
                                                      {configJSON.originAddress.split(' ')[0]}
                                                      <br />
                                                      {configJSON.originAddress.split(' ')[1]}
                                                    </Typography>
                                                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>
                                                      {selectedCard?.attributes.origin_address.address}<br />
                                                      {selectedCard?.attributes.origin_address?.city}, {selectedCard?.attributes.origin_address?.state} <br />
                                                      {selectedCard?.attributes.origin_address?.country} - {selectedCard?.attributes.origin_address?.zip_code}
                                                    </Typography>
                                                  </Box>
                                                  <Divider sx={webStyles.dividerStyles} />

                                                  <Box display={'flex'} gap={'6.5rem'}>
                                                    <Typography sx={{ ...webStyles.fontStyle_9 }}>
                                                      {configJSON.destinationAddress.split(' ')[0]}
                                                      <br />
                                                      {configJSON.destinationAddress.split(' ')[1]}
                                                    </Typography>
                                                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>
                                                      {selectedCard?.attributes.destination_address?.address} <br />
                                                      {selectedCard?.attributes.destination_address?.city}, {selectedCard?.attributes.destination_address?.state} <br />
                                                      {selectedCard?.attributes.destination_address?.country} - {selectedCard?.attributes.destination_address?.zip_code}
                                                    </Typography>
                                                  </Box>
                                                  <Divider sx={webStyles.dividerStyles} />

                                                  <Box style={webStyles.displayFlexBox}>
                                                    <Typography sx={webStyles.fontStyle_9}>{configJSON.loadDetails}</Typography>
                                                    <Typography sx={{ ...webStyles.fontStyle_8, fontSize: '18px !important' }}>{configJSON.loarem}</Typography>
                                                  </Box>
                                                </Box>
                                              </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                    <Box>
                                    </Box>
                                  </Box>
                                </Box>
                              )}
                              </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    </ThemeProvider>
                </Box>
            </Box >
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderColor: '#BAC9DA',
    borderLeftWidth: 2,
    margin: '-7px 0 -7px -8px',
  },
}));

const webStyles = {
  displayFlexBox:{display:'flex', gap:'8rem'},
  locationBox:{backgroundColor:'#F19F40', height:'67px', width:'67px', borderRadius:'50%', marginRight:'20px'},
  routeBox:{borderLeft: "23px solid #C17F33", boxShadow:'0px 4px 15px 0px #00000020', padding: '16px 0 16px 16px', gap:'15px',minWidth: '50%', borderRadius: '10px', border: '0px, 0px, 0px, 4px', backgroundColor: '#FFFFFF' },
  fontStyle_8: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    color: "#1C1917",
    textAlign: 'Left'
  },
  fontStyle_9: {
      fontFamily: "Inter",
      fontSize: "18px",
      fontWeight: 400,
      color: "#475569"
  },
  dividerStyles: {
    borderStyle: 'dotted',
    borderWidth: '1px',
    borderColor: '#BAC9DA',
  },
  cancelButton: {
    height: '44px', width: '170px',
    color: '#B57730',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    backgroundColor: '#FDF1E2',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FDF1E2',
    }
  },
  LogoutButton: {
    height: '44px', width: '170px',
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    cursor: 'pointer',
    background: 'linear-gradient(99.09deg, #915F26 2.64%, #F19F40 100%)',
    '&:hover': {
      background: 'linear-gradient(99.09deg, #915F26 2.64%, #F19F40 100%)',
    }
  },
  justifySpace:{display: 'flex', justifyContent: 'space-between'},
  flexText:{ color: '#0F172A', fontSize: '14px', fontFamily: 'text-3xl/font-bold', fontWeight: 700, },
  flexBox:{borderLeft: "4px solid #C17F33", boxShadow:'0px 4px 15px 0px #00000020', padding: '16px 0 0 16px', height: '110px', width: '170px', borderRadius: '6px', border: '0px, 0px, 0px, 4px', backgroundColor: '#FFFFFF' },
  phone1:{width:'22px', height:'22px', color: '#334155'},
  chatBox:{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center' },
  applicantName:{display: 'flex', justifyContent: 'center', color: '#0F172A', fontSize: '30px', fontFamily: 'text-3xl/font-bold', fontWeight: 700, marginTop: '21px' },
  detailBox1:{ height: '100%',},
  chatBoxStyle1: {
    width: '130px',
    height: '40px',
    backgroundColor: '#FBE1C4',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    color:'#B57730'
  },
  accordianText:{
    backgroundColor: '#475569',
  },
  coverBox:{ padding: '30px',backgroundColor: '#FFF',display:'flex', gap:'40px' },
  coverBox1: { padding: '30px',backgroundColor: '#FFF' },
  selectStyle:{
    fontFamily: 'Inter',
    marginLeft: '-10px',
    color: '#475569',
    opacity: 0.9,
    fontSize: '16px',
    fontWeight:400
  },
  input: {
    height: '56px',
    radius: '8px',
    padding: '26px 10px',
    marginLeft: '-10px',
    width: '327px',
  },
  allSelectColor: { backgroundColor: '#e9eef2', borderRadius: '0px'},
  allFilterFont: { color: '#0F172A', cursor: "pointer", fontSize: '24px', fontWeight: 400, fontFamily: "Inter" },
  driverDetails: {
    backgroundColor: '#E2E8F0',
    padding: '0 16px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    height:'120px',
    justifyContent: 'space-between',

  },
  driverPick: {
    width: '70px',
    height: '70px'
  },
  nameContainer: {
    flexDirection: 'column',
    display: 'flex',
    gap: '5px',
  },
  fontStyle_7: {
    fontFamily: "Inter",
    fontSize: '22px',
    fontWeight: 800,
    color: "#0F172A"
  },
  fontStyle_5: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: "Inter",
    color: "#0F172A"
  },
  fontStyle_6: {
    fontWeight: 700,
    fontFamily: "Inter",
    color: "#1C1917",
    fontSize: '16px',
  },
  boxColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  trackingDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',
  },
  driverInfoCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px'
  },
  mainTruckBox:{
    backgroundColor: '#e9eef2',
    borderRadius: '16px',
    marginBottom: '50px',
  },
  trackingBoxStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '50px 250px 0px 50px',
  },
  infoBoxPadding: {    
    marginTop: '2%',
    borderRadius: '16px',
    border:'2px solid #e9eef2',
    boxShadow: '0px 4px 4px 0px #00000040',
  },
  priceText:{fontWeight: 700, fontSize: '36px', color: '#475569'},
  buttonStyle: {
    color: "#a87f51",
    backgroundColor:'#FBE1C4',
    borderRadius: '20px',
    padding: '5px 20px',
    height:'40px',
    fontSize: '16px',
    fontWeight: 700,
    alignItems: 'center',
  },
  viewBox: {
    backgroundColor: '#915F26',
    borderRadius: '0px 0px 13px 13px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 20px',
  },
  dateBox: {
    position: 'relative',
    right: '70px',
    top: '40px'
  },
  dateFontSyle_1: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#475569',
    fontFamily: "Inter"
  },
  dateFontSyle_2: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#BDBDBD',
    fontFamily: "Inter"
  },
  statusBox: {
    fontFamily: "Inter",
  },
  fontSyle_3: {
    fontFamily: "Inter",
    fontSize: '16px',
    fontWeight: 700,
    color: "#FFFFFF",
    backgroundColor: '#215089',
    borderRadius: '3px 0px 0px 3px',
    padding: '5px 20px',
    marginRight:'-20px',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  fontSyle_4: {
    fontFamily: "Inter",
    fontSize: '18px',
    fontWeight: 400,
    color: "#0F172A"
  },
  fontSyle_5: {
    fontFamily: "Inter",
    fontSize: '18px',
    fontWeight: 400,
    color: "#FFFFFF",
  },
  headerFont: {
    fontWeight: 700,
    fontSize: '30px',
    fontFamily: "Inter",
    color: "#215089"
  },
  boxPadding: {
    padding: '20px 20px 10px 20px'
  },
  cardHeader: {
    padding: '20px 20px 0px 20px',
  },
  content: {
    display: "flex",
    flex: 1,
  },
  mainContent: {
    flex: 1,
    padding: "2rem",
    backgroundColor: '#E2E8F0',
  },
  container: {
    flexDirection: "column",
    display: "flex",
    overflowX: 'hidden'
  },
  tabContainer: {
    backgroundColor: '#FFFFFF',
    border: '2px solid #215089',
    borderRadius: '16px',
    padding: '2rem',
  },
  styledCardContainer: {
    width: '55%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  cardStyle: {
    marginTop: '4%',
    border: '1px solid #FBE1C4',
    cursor: 'pointer',
    borderRadius: '16px',
  },
};
// Customizable Area End